@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300;1,500&display=swap');
@import '~scss/variables';
* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

html,
body {
	height: 100%;
	font-family: 'Roboto', sans-serif;
	margin: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

a,
button {
	cursor: pointer;
	transition: 0.25s linear;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	outline: none;
	background: transparent;
	border: none;

	&:disabled {
		cursor: not-allowed;
	}
}

input,
select,
textarea {
	border-radius: 0;
	outline: none;
	font-family: 'Roboto', sans-serif;

	&:invalid,
	&:required {
		box-shadow: none; // fix for FF
	}
}

#root {
	min-height: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

%container {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	padding-right: 50px;
	padding-left: 50px;

	&_fluid {
		max-width: 100%;
	}

	@include laptop {
		padding-right: 30px;
		padding-left: 30px;
	}

	@include landscape {
		padding-right: 16px;
		padding-left: 16px;
	}
}

// GLOBAL CLASSNAMES
.container {
	@extend %container;
	&_fluid {
		@extend %container;
		max-width: 100%;
	}
}
