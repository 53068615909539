// Screen sizes
$breakpoint-desktop: 1440px;
$breakpoint-laptop: 1280px;
$breakpoint-ipad: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 576px;

// From desktop
@mixin laptop {
	@media (max-width: $breakpoint-desktop - 0.2px) {
		@content;
	}
}

// From laptop
@mixin ipad {
	@media (max-width: $breakpoint-laptop - 0.2px) {
		@content;
	}
}

// From ipad
@mixin tablet {
	@media (max-width: $breakpoint-ipad - 0.2px) {
		@content;
	}
}

// From ipad
@mixin landscape {
	@media (max-width: $breakpoint-tablet - 0.2px) {
		@content;
	}
}

// From landscape
@mixin phone {
	@media (max-width: $breakpoint-phone - 0.2px) {
		@content;
	}
}

$color-dark: #263238;
$color-dark-text: #485261;
$color-green: #1edeb0;
$color-red: #ff5b4a;
$color-yellow: #ffcf00;
$color-blue: #2980b9;
$color-disabled: #d6dee8;
$color-grey: #b6c0cf;
$color-grey-light: #d7d7ed;
$color-white: #fff;
$color-white-surface: #f7f9fe;

// FONT
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;

@mixin paper {
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}
