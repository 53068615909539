.Root {
	height: 100%;
	width: 100%;
	display: table !important;
}

.BrowserValidator {
	padding: 32px;
	height: 100%;
	display: table-cell;
	text-align: center;
	vertical-align: middle;

	button {
		display: inline-block;
		width: auto;
	}
}

.Text {
	white-space: pre-wrap;
}
