@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300;1,500&display=swap);
*{box-sizing:border-box;-webkit-tap-highlight-color:transparent}html,body{height:100%;font-family:'Roboto', sans-serif;margin:0}ul{margin:0;padding:0;list-style:none}a,button{cursor:pointer;transition:0.25s linear;text-decoration:none;font-family:inherit;font-size:inherit;color:inherit;outline:none;background:transparent;border:none}a:disabled,button:disabled{cursor:not-allowed}input,select,textarea{border-radius:0;outline:none;font-family:'Roboto', sans-serif}input:invalid,input:required,select:invalid,select:required,textarea:invalid,textarea:required{box-shadow:none}#root{min-height:100%;height:100%;display:flex;flex-direction:column}.container,.container_fluid{max-width:1440px;width:100%;margin:0 auto;padding-right:50px;padding-left:50px}@media (max-width: 1439.8px){.container,.container_fluid{padding-right:30px;padding-left:30px}}@media (max-width: 767.8px){.container,.container_fluid{padding-right:16px;padding-left:16px}}.container_fluid{max-width:100%}

.Home_Component__3slYF{padding-top:24px;padding-bottom:24px;flex-grow:1;display:flex;flex-direction:column;align-items:center;text-align:center;justify-content:center;width:100%}.Home_Logo__3cg67{width:80px;height:80px}.Home_Name__AwDIE{margin:8px 0;font-size:32px;line-height:37px;color:#1edeb0;font-weight:400}.Home_Nav__2FD4Q{padding:16px 0}.Home_Nav__2FD4Q>*:not(:last-child){margin-bottom:40px}@media (max-width: 767.8px){.Home_Nav__2FD4Q{padding:0}.Home_Nav__2FD4Q>*:not(:last-child){margin-bottom:24px}}.Home_Content__1L0QQ{flex-grow:1;display:flex;flex-direction:column;align-items:center;justify-content:center}section.Home_Section__zSxnJ{flex-grow:unset;margin:40px auto}.Home_Title__3pnws{color:#263238;text-align:center;white-space:pre-wrap;margin:0 0 68px}@media (max-width: 767.8px){.Home_Title__3pnws{font-size:24px;line-height:28px;margin-bottom:80px}}@media (max-width: 575.8px){.Home_Title__3pnws{font-size:16px;line-height:19px;margin-bottom:24px}}.Home_Params__3QgWc>div{margin-left:auto;margin-right:auto;max-width:714px;width:100%;margin-bottom:42px;display:flex;align-items:flex-start}@media (max-width: 575.8px){.Home_Params__3QgWc>div{flex-direction:column}}.Home_Params__3QgWc>div img{flex-shrink:0;margin-right:32px}@media (max-width: 575.8px){.Home_Params__3QgWc>div img{width:32px;height:32px;margin:0 0 8px}}.Home_Params__3QgWc>div:last-child{margin-bottom:0}.Home_Params__3QgWc>div:nth-child(2) p,.Home_Params__3QgWc>div:nth-child(4) p{align-self:center}.Home_Params__3QgWc p{margin:0;font-size:16px;line-height:19px;white-space:pre-wrap;text-align:left;color:#263238}.Home_Params__3QgWc p a{color:#2980b9;text-decoration:underline}@media (max-width: 575.8px){.Home_Params__3QgWc p{font-size:14px;line-height:16px}}

.Button_Btn__1sjI4{text-transform:uppercase;display:block;padding:20px;font-weight:500;color:#fff;font-size:14px;line-height:16px;border-radius:4px;width:100%;letter-spacing:0.75px;transition:0.2s background}.Button_Btn__1sjI4>span{width:100%;display:flex;align-items:center;justify-content:center}.Button_Btn__1sjI4:disabled:not(.Button_Btn_loading__1LNAC){background:#d6dee8}.Button_Btn_red__1YkSH{background:#ff5b4a}.Button_Btn_blue__11XV-{background:#2980b9}.Button_Btn_green__BjN6u{background:#1edeb0}.Button_Btn_link__8xvnm{display:block;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;font-size:16px;line-height:24px;color:#485261;text-transform:uppercase}.Button_Btn_link__8xvnm:hover{color:#263238}@media (max-width: 767.8px){.Button_Btn__1sjI4{padding:12px}}

.Section_Component__2_BCz{margin:auto;max-width:486px;width:100%;flex-grow:1;position:relative;min-width:0}.Section_Content__1Pg-6{background:#ffffff;box-shadow:0px 2px 4px rgba(0,0,0,0.12);border-radius:4px;padding:32px}@media (max-width: 767.8px){.Section_Content__1Pg-6{padding:24px 16px}}.Section_Title__2fEgo{font-size:32px;line-height:37px;color:#485261;margin:0 0 24px;text-align:center;text-transform:uppercase}@media (max-width: 767.8px){.Section_Title__2fEgo{font-size:20px;line-height:23px;margin-bottom:16px}}

.Footer_Component__2PMBl{background:#263238;color:#fff;flex:none}.Footer_Row__rBuGB{display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap}.Footer_Copyright__21NUm,.Footer_Nav__3sOaa a{font-weight:500;font-size:16px;line-height:19px;letter-spacing:0.75px}@media (max-width: 767.8px){.Footer_Copyright__21NUm,.Footer_Nav__3sOaa a{font-size:12px;line-height:15px}}.Footer_Payments__1Xo56{display:flex;flex-grow:1;align-items:center;margin-right:40px}.Footer_Payments__1Xo56>*:not(:last-child){margin-right:40px}.Footer_Copyright__21NUm{margin:0;padding:8px 0;flex-shrink:0}.Footer_Nav__3sOaa{display:flex;align-items:center;flex-wrap:wrap;max-width:100%;min-width:0;padding:8px 0;flex-shrink:0;margin-right:80px}.Footer_Nav__3sOaa a{margin-right:40px}@media (max-width: 767.8px){.Footer_Nav__3sOaa a{margin-right:24px}}.Footer_Nav__3sOaa a:last-child{margin-right:0}@media (max-width: 575.8px){.Footer_Nav__3sOaa{flex-direction:column;align-items:flex-start}.Footer_Nav__3sOaa a{margin-right:0;margin-bottom:8px}}

header.Header_Header__1MKEg{flex-shrink:0;display:flex;align-items:center;justify-content:space-between;margin-bottom:24px}@media (max-width: 767.8px){header.Header_Header__1MKEg{flex-direction:column;justify-content:center}}.Header_Logo__11OHU{display:inline-flex;align-items:center;font-size:24px;line-height:28px;color:#fff}.Header_Logo__11OHU svg{height:60px;width:60px;margin-right:24px}@media (max-width: 767.8px){.Header_Logo__11OHU{margin-bottom:64px}}

.DropdownForm_Component__3gVV7{position:relative}.DropdownForm_BtnControl__2r2aG{display:inline-block;text-transform:uppercase;padding:12px 10px;font-size:16px;line-height:19px;color:#fff;border-radius:4px;background:#485261;font-weight:500}.DropdownForm_BtnControl__2r2aG>span{display:flex;align-items:center;transition:inherit}.DropdownForm_BtnControl__2r2aG svg{margin-left:12px;transition:inherit}.DropdownForm_BtnControl__2r2aG svg path{fill:#fff}.DropdownForm_BtnControl_open__MV74I{background:#343e4d}.DropdownForm_BtnControl_open__MV74I svg{transform:rotate(180deg)}.DropdownForm_LinkActive__1bk4V{font-weight:700}.DropdownForm_Nav__29g0V{position:absolute;z-index:1;top:calc(100% + 8px);background:#fff;box-shadow:0px 1px 16px rgba(0,0,0,0.12);border-radius:4px;padding:6px 0;width:100%}.DropdownForm_Nav__29g0V a{text-transform:uppercase;font-size:14px;line-height:20px;letter-spacing:0.25px;display:block;padding:6px 12px;margin-bottom:12px}.DropdownForm_Nav__29g0V a:hover{background:#f7f9fe}.DropdownForm_Nav__29g0V a:last-child{margin-bottom:0}

.Field_Component__2d38X{display:flex;align-items:center;justify-content:center;border:1px solid rgba(38,50,56,0.16);padding:9px 11px;transition:.2s;border-radius:4px}.Field_Component_focus__KCWXR{border-color:#263238}.Field_Component_error__1Isow{border-color:#ff5b4a}.Field_Component_success__2pvzt{border-color:#1edeb0}@media (max-width: 767.8px){.Field_Component__2d38X{padding:7px 10px}}.Field_Input__vaB09{flex-grow:1;min-width:0;font-size:16px;line-height:24px;border:none;padding:6px 0;color:#263238}.Field_Input__vaB09::-webkit-input-placeholder{color:#b6c0cf}.Field_Input__vaB09:-ms-input-placeholder{color:#b6c0cf}.Field_Input__vaB09::-ms-input-placeholder{color:#b6c0cf}.Field_Input__vaB09::placeholder{color:#b6c0cf}@media (max-width: 767.8px){.Field_Input__vaB09{padding:0;font-size:12px}}.Field_RightControl__36C41{display:flex;align-items:center;flex-shrink:0}.Field_RightControl__36C41>*{margin-left:12px}.Field_Icon__1W92_{width:20px;height:20px}

.GenerateWallet_Component__2wqDg{flex-grow:1;display:flex;align-items:center;justify-content:center}.GenerateWallet_Btn_link__2GzLD{font-size:14px;line-height:16px;color:#2980b9}.GenerateWallet_Btn_link__2GzLD:hover{text-decoration:underline}.GenerateWallet_AgreeCopy__Xq9N6{color:#ff5b4a;font-size:20px}.GenerateWallet_BtnSubmit_loading__1LH5i{padding:16px}@media (max-width: 767.8px){.GenerateWallet_BtnSubmit_loading__1LH5i{padding:8px}}.GenerateWallet_RuleList__oLlVD{margin-bottom:24px}.GenerateWallet_AgreeGroup__1rN0R{margin-bottom:24px}.GenerateWallet_AgreeGroup__1rN0R>*:not(:last-child){margin-bottom:12px}.GenerateWallet_Tooltip__3SXeH{border-radius:17px;padding:10px 12px;color:#f7f9fe;font-size:12px;line-height:14px;background:rgba(38,50,56,0.8);position:fixed;z-index:5;left:50%;transform:translateX(-50%);bottom:98px;transition:0.2s;opacity:0;visibility:hidden}.GenerateWallet_Tooltip_show__1qOhZ{opacity:1;visibility:visible}.GenerateWallet_BtnBack__1Mkom{margin:24px auto 0}.GenerateWallet_ErrorMessage__1Rgf8{font-size:12px;line-height:1.4em;text-align:center;color:#ff5b4a;min-height:1.4em;margin:0 0 8px;word-break:break-word}

.FormField_FormField__DBf3u{margin-bottom:24px}.FormField_FormFieldLabel__3HDKG{display:flex;justify-content:space-between;align-items:center;padding:0 12px 12px;font-size:14px;line-height:16px;color:#485261}@media (max-width: 767.8px){.FormField_FormField__DBf3u{margin-bottom:16px}}

.Rule_Rule__1sfFx{display:flex;align-items:flex-start;white-space:pre-wrap;font-size:12px;line-height:140%;color:#263238;margin-bottom:12px}.Rule_Rule__1sfFx:last-child{margin-bottom:0}.Rule_Rule_hidden__8UNmG{opacity:0;min-height:16px}.Rule_Rule__1sfFx svg{width:16px;height:16px;flex-shrink:0;margin-right:8px}

.Checkbox_Checkbox__3KIv6{display:flex;align-items:center;justify-content:space-between;cursor:pointer;padding:0 12px}.Checkbox_CheckboxLabel__3oXnQ{font-size:14px;line-height:16px;color:#485261}.Checkbox_CheckboxLabel__3oXnQ a{color:#2980b9}.Checkbox_CheckboxLabel__3oXnQ a:hover{text-decoration:underline}@media (max-width: 767.8px){.Checkbox_CheckboxLabel__3oXnQ{font-size:12px;line-height:14px}}.Checkbox_Switcher__9Leph{display:block;width:34px;height:14px;position:relative;transition:.2s;flex-shrink:0;background:#d6dee8;border-radius:34px}.Checkbox_Switcher__9Leph::after{content:'';width:20px;height:20px;transition:.2s;background:#f7f9fe;box-shadow:0px 1px 1px rgba(0,0,0,0.237602);position:absolute;top:50%;transform:translateY(-50%);left:-3px;border-radius:50%}.Checkbox_Switcher_active__cvogj{background:rgba(30,222,176,0.24)}.Checkbox_Switcher_active__cvogj::after{box-shadow:0px 1px 2px rgba(48,79,254,0.54);background:#1edeb0;transform:translateY(-50%) translateX(-100%);left:calc(100% + 3px)}

.Param_Param__rzZ41{margin-bottom:24px;border-bottom:1px solid #d7d7ed;padding:0 12px 16px}.Param_ParamLabel__nxMjL{font-size:14px;line-height:16px;display:block;color:#485261;margin:0 0 12px}.Param_ParamValue__1CRhD{display:flex;align-items:center;justify-content:space-between;font-weight:500;font-size:16px;color:#485261;font-weight:300;font-style:italic;word-break:break-all;line-height:19px}.Param_ParamValue__1CRhD button{margin-left:12px;flex-shrink:0}@media (max-width: 767.8px){.Param_Param__rzZ41{margin-bottom:16px}.Param_ParamValue__1CRhD,.Param_ParamLabel__nxMjL{font-size:12px;line-height:14px}}.Param_BtnCopy__mZ1fo{padding:0;display:inline-flex}

.Attention_Attention__2tYfk{display:flex;margin-bottom:40px;align-items:center}.Attention_AttentionIcon__2g3fl{display:flex;align-items:center;justify-content:center;font-size:20px;line-height:140%;border:1px dashed #ff5b4a;color:#ff5b4a;font-weight:700;width:40px;height:40px;border-radius:50%;background:rgba(255,91,74,0.1);flex-shrink:0;margin-right:20px}.Attention_Attention__2tYfk p{font-size:12px;line-height:140%;color:#263238;white-space:pre-wrap;margin:0}@media (max-width: 767.8px){.Attention_Attention__2tYfk p{font-size:12px}}

.CurrencyField_SelectOption__1KSus{display:flex;align-items:center;font-size:inherit;line-height:inherit;padding-right:16px;padding-left:16px}.CurrencyField_SelectOption__1KSus svg{transition:inherit;opacity:0;margin-right:12px}.CurrencyField_SelectOption_selected__JTs4F{font-weight:700}.CurrencyField_SelectOption_selected__JTs4F svg{opacity:1}.CurrencyField_Hint__2fy4b{color:#263238;font-size:16px;margin:12px 0}

.Select_SelectField_active__3T6oj{border-color:#2980b9}.Select_Component__3adfx{position:relative}.Select_Component__3adfx input{cursor:pointer}.Select_Icon__eXNrz{transition:0.2s}.Select_Icon__eXNrz path{transition:inherit;fill:#b6c0cf}.Select_Icon_active__olXsl{transform:rotate(180deg)}.Select_Icon_active__olXsl path{fill:#2980b9}.Select_List__1SZbw{position:absolute;bottom:calc(100% + 8px);left:0;z-index:1;width:100%;background:#fff;box-shadow:0px 1px 16px rgba(0,0,0,0.12);border-radius:4px;max-height:112px;padding:8px 0;overflow:auto}.Select_List__1SZbw::-webkit-scrollbar{display:none}.Select_List__1SZbw li{font-size:14px;line-height:20px}.Select_List__1SZbw li:hover{background:#f7f9fe}.Select_List__1SZbw li>*{width:100%;text-align:left;padding-top:4px;padding-bottom:4px}

.Steps_Step__19Kl9{font-weight:900;font-size:48px;line-height:56px;color:#d7d7ed;display:flex;align-items:center;transition:.2s}.Steps_StepList__Ioc25{display:flex;align-items:center;flex-direction:column;margin-bottom:24px;position:absolute;right:calc(100% + 80px);top:50%;transform:translateY(-50%)}.Steps_StepList_green__LLsvI .Steps_Step_active__2BBsq{color:#1edeb0}.Steps_StepList_green__LLsvI .Steps_StepIcon_active__2APOU path{fill:#1edeb0}.Steps_StepList_blue__18sFY .Steps_Step_active__2BBsq{color:#2980b9}.Steps_StepList_blue__18sFY .Steps_StepIcon_active__2APOU path{fill:#2980b9}.Steps_StepIcon__1k_w-{margin:48px 0;transform:rotate(90deg)}.Steps_StepIcon__1k_w- path{transition:.2s}@media (max-width: 767.8px){.Steps_Step__19Kl9{font-size:32px;line-height:37px}.Steps_StepList__Ioc25{position:static;flex-direction:row;transform:none;justify-content:center}.Steps_StepIcon__1k_w-{margin:0 24px;transform:none}}

.FormDisabled_Component__1eKjt{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:60px 0px;font-size:14px;line-height:16px;color:#485261;white-space:pre-wrap;text-align:center}.FormDisabled_Component__1eKjt svg{margin-bottom:32px;width:80px;height:80px}@media (max-width: 767.8px){.FormDisabled_Component__1eKjt{padding:0}}

.Modal_Component__1S4jW{position:fixed;z-index:100;width:100%;height:100%;overflow:auto;display:flex;top:0;padding:32px 50px;left:0;background:transparent;transition:all cubic-bezier(0.645, 0.045, 0.355, 1)}.Modal_Component__1S4jW::-webkit-scrollbar{display:none}.Modal_Component__1S4jW.Modal_show__1g0pZ{background:rgba(102,118,129,0.8)}.Modal_Component__1S4jW.Modal_show__1g0pZ .Modal_Modal__32zLH{transform:translateY(0%);opacity:1}.Modal_Modal__32zLH{max-width:100%;margin:auto;position:relative;display:flex;flex-direction:column;background:#fff;border-radius:4px;overflow:hidden;transform:translateY(50%);opacity:0;transition:all cubic-bezier(0.68, -0.55, 0.265, 1.55);padding:32px;width:486px}.Modal_Modal__32zLH>*{flex-grow:1}

.CopyAgreement_Component__3S83U{text-align:center}.CopyAgreement_Icon__3HA7N{margin:0 auto 36px;display:block;width:80px;height:80px}.CopyAgreement_Message__3ltrs{color:#485261;font-size:14px;line-height:16px;margin:0 0 56px}.CopyAgreement_BtnCancel__2J-6g{font-size:16px;line-height:24px;color:#485261;display:block;text-transform:uppercase;margin:24px auto 0;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}

.Resource_Component__3dhOQ{flex-grow:1;display:flex;align-items:center}.Resource_Unit__1hYx0{font-size:16px;line-height:24px;color:#485261}@media (max-width: 767.8px){.Resource_Unit__1hYx0{font-size:12px;line-height:24px}}.Resource_BtnSubmit_loading__3YTcJ{padding:16px}@media (max-width: 767.8px){.Resource_BtnSubmit_loading__3YTcJ{padding:8px}}.Resource_RuleList__2fpna{margin-bottom:24px}.Resource_BtnBack__3Ft7L{margin:24px auto 0}.Resource_Stat__3hC9P{flex-shrink:0;margin-right:24px;display:flex;align-items:center}.Resource_Stat__3hC9P svg{flex-shrink:0}.Resource_StatIconWarning_warning__c4JDw path{fill:#ffcf00}.Resource_StatIconSuccess__3GlLk{width:32px;height:32px}.Resource_StatIconSuccess__3GlLk path{fill:#2980b9}.Resource_StatDetails__1UcQf{flex-shrink:0;margin-left:12px;font-size:12px;line-height:14px;color:#b6c0cf}.Resource_StatDetails_warning__2M04x{color:#ffcf00}.Resource_StatDetails_limit__P228w{color:#ff5b4a}.Resource_StatDetails__1UcQf span{font-size:14px;line-height:16px;color:#2980b9;font-weight:500;display:block;margin-bottom:4px}.Resource_FormField__1PD0w{display:flex;align-items:center;margin-bottom:24px}.Resource_Field__2zmr2{flex-grow:1;min-width:0}.Resource_ErrorMessage__1JqNJ{font-size:12px;line-height:1.4em;text-align:center;color:#ff5b4a;min-height:1.4em;margin:0 0 8px;word-break:break-word}

.App_Component__1Bwx9{display:flex;flex-direction:column;background-color:#f7f9fe;background-position:top -240px left -100px, bottom -200px right -100px;background-repeat:no-repeat;padding-top:24px;flex:1 0 auto;padding-bottom:48px;transition:0.2s;background-image:url(/static/media/figure_green_1.90d6ab4c.svg),url(/static/media/figure_blue_2.ed06cf1a.svg)}.App_Component_generator__36PdM{background-image:url(/static/media/figure_green_1.90d6ab4c.svg),url(/static/media/figure_green_2.d8c7382d.svg)}.App_Component_resource__1X8nl{background-image:url(/static/media/figure_blue_1.5d7d5bb6.svg),url(/static/media/figure_blue_2.ed06cf1a.svg)}@media (max-width: 767.8px){.App_Component__1Bwx9{padding-bottom:32px;background-position:top -300px center, bottom -400px right -100px}}

div.LanguageSwitcher_Row__1WCEG{display:flex;justify-content:flex-end;margin-top:16px}.LanguageSwitcher_Component__1VDez{position:relative}.LanguageSwitcher_Control__2s91t,.LanguageSwitcher_LngBtn__cXdQE{font-size:16px;line-height:19px;letter-spacing:0.75px}.LanguageSwitcher_Control__2s91t{white-space:nowrap;font-weight:500;display:inline-block;color:#485261}.LanguageSwitcher_Control__2s91t>span{display:flex;align-items:center;transition:inherit}.LanguageSwitcher_Control__2s91t svg{margin-left:8px;transition:inherit}.LanguageSwitcher_Control_open__Qe_90 svg{transform:rotate(180deg)}.LanguageSwitcher_Content__2TIRX{position:absolute;bottom:calc(100%)}.LanguageSwitcher_LngBtn__cXdQE{color:#485261;margin-bottom:16px}

.Privacy_Component__2TUjC{display:flex;flex-grow:1;flex-direction:column}.Privacy_Component__2TUjC a{color:-webkit-link}.Privacy_Component__2TUjC ol{counter-reset:item}.Privacy_Component__2TUjC ol h3{display:inline-block}.Privacy_Component__2TUjC ol li{display:block;margin-bottom:16px}.Privacy_Component__2TUjC ol li:before{content:counters(item, ".") ". ";counter-increment:item}.Privacy_Text_center__3J2DU{text-align:center}.Privacy_Content__OqDfa{background:#ffffff;box-shadow:0px 2px 4px rgba(0,0,0,0.12);border-radius:4px;padding:32px;flex-grow:1;color:#485261}.Privacy_Content__OqDfa h2{font-size:32px;line-height:37px;margin:0 0 24px}@media (max-width: 767.8px){.Privacy_Content__OqDfa h2{font-size:20px;line-height:24px}}.Privacy_Content__OqDfa p{font-size:14px;line-height:16px;margin:0 0 24px}.Privacy_Content__OqDfa p:last-child{margin-bottom:0}

.Company_Component__1U65K{display:flex;flex-grow:1;flex-direction:column}.Company_Content__3RLxv{background:#ffffff;box-shadow:0px 2px 4px rgba(0,0,0,0.12);border-radius:4px;padding:32px;flex-grow:1;color:#485261}.Company_Content__3RLxv h2{font-size:32px;line-height:37px;margin:0 0 24px}@media (max-width: 767.8px){.Company_Content__3RLxv h2{font-size:20px;line-height:24px}}.Company_Content__3RLxv p{font-size:14px;line-height:16px;margin:0 0 24px}.Company_Content__3RLxv p:last-child{margin-bottom:0}

div.Error_Component__Wwmi4{flex-grow:1;display:flex;align-items:center;justify-content:center}.Error_Content__2611o{text-align:center;color:#485261}.Error_Content__2611o p{font-size:14px;line-height:16px;margin:16px 0 48px}.Error_Status__qiKvE{display:flex;align-items:center;justify-content:center;font-weight:300;font-size:80px;line-height:94px}.Error_Status__qiKvE svg{width:64px;height:64px;margin:0 8px}.Error_Status__qiKvE svg path{fill:#485261}

.Order_Component__UY1fB{flex-grow:1;display:flex;align-items:center;justify-content:center}.Order_Details__3FL3j{text-align:center}.Order_Details__3FL3j svg{display:inline-block;width:80px;height:80px}.Order_Details__3FL3j p{margin:32px 0 56px;font-size:14px;line-height:16px;color:#485261;white-space:pre-wrap;word-break:break-word}.Order_Hint__29wld{font-size:14px;line-height:16px;color:#485261;margin:24px 0;text-align:center}.Order_Param__2-iQt{display:flex;align-items:center}.Order_Param__2-iQt:not(:last-child){margin-bottom:16px;padding-bottom:16px;border-bottom:1px solid #d6dee8}.Order_ParamList__3SoId:not(:last-child){margin-bottom:16px}.Order_ParamLabel__ZuOPj{color:#263238;padding-right:1ch}.Order_ParamValue__aG1e4{color:#485261}.Order_TransactionIdLink__1WSMy{padding:2px 6px;border-radius:12px;color:#fff;background:#1edeb0;display:inline-flex;align-items:center}.Order_TransactionIdLink__1WSMy svg{margin-right:8px}.Order_WalletLinks__r4Cqb{font-size:16px;color:#485261;margin:0}.Order_WalletLinks__r4Cqb a{text-decoration:underline;color:#2980b9}

.Terms_Component__7VJkq{display:flex;flex-grow:1;flex-direction:column}.Terms_Component__7VJkq ul{list-style-type:disc;list-style-type:initial;padding:unset;-webkit-padding-start:40px;padding-inline-start:40px}.Terms_Component__7VJkq p,.Terms_Component__7VJkq font{font-family:'Roboto', sans-serif !important;color:#485261 !important;font-size:16px !important;line-height:1.4em !important}.Terms_Component__7VJkq a,.Terms_Component__7VJkq a font{color:-webkit-link !important}.Terms_Component__7VJkq ol{counter-reset:item}.Terms_Component__7VJkq ol h3{display:inline-block}.Terms_Component__7VJkq ol li{display:block;margin-bottom:16px}.Terms_Component__7VJkq ol li:before{content:counters(item, ".") ". ";counter-increment:item}.Terms_Text_center__1YGJJ{text-align:center}.Terms_Content__2kypl{background:#ffffff;box-shadow:0px 2px 4px rgba(0,0,0,0.12);border-radius:4px;padding:32px;flex-grow:1;color:#485261}.Terms_Content__2kypl h2{font-size:32px;line-height:37px;margin:0 0 24px}@media (max-width: 767.8px){.Terms_Content__2kypl h2{font-size:20px;line-height:24px}}.Terms_Content__2kypl p{font-size:14px;line-height:16px;margin:0 0 24px}.Terms_Content__2kypl p:last-child{margin-bottom:0}

.WithVersionController_Component__1NxBm{justify-content:center;align-items:center;text-align:center}.WithVersionController_Component__1NxBm p{white-space:pre-wrap;color:#263238}.WithVersionController_Reconnecting__1rKJ3{position:fixed;z-index:10000000;display:flex;align-items:center;justify-content:center;flex-direction:column;text-align:center;width:100%;height:100%;background:#fff}.WithVersionController_Reconnecting_blur__15UDI{-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);background:transparent}.WithVersionController_Reconnecting__1rKJ3 p{margin:24px 0 0}.WithVersionController_UpdateScreen__2JT03{flex-grow:1;display:flex;align-items:center;text-align:center;color:#485261}

.Faq_Component__3qmn_{display:flex;flex-grow:1;flex-direction:column}.Faq_Component__3qmn_ a{color:-webkit-link}.Faq_Component__3qmn_ ol{counter-reset:item}.Faq_Component__3qmn_ ol h3{display:inline-block}.Faq_Component__3qmn_ ol li{display:block;margin-bottom:16px}.Faq_Component__3qmn_ ol li:before{content:counters(item, ".") ". ";counter-increment:item}.Faq_Text_center__tbdUX{text-align:center}.Faq_Content__2cF8j{background:#ffffff;box-shadow:0px 2px 4px rgba(0,0,0,0.12);border-radius:4px;padding:32px;flex-grow:1;color:#485261}.Faq_Content__2cF8j h2{font-size:32px;line-height:37px;margin:0 0 24px}@media (max-width: 767.8px){.Faq_Content__2cF8j h2{font-size:20px;line-height:24px}}.Faq_Content__2cF8j p{font-size:14px;line-height:16px;margin:0 0 24px}.Faq_Content__2cF8j p:last-child{margin-bottom:0}

.BrowserValidator_Root__1Rq9f{height:100%;width:100%;display:table !important}.BrowserValidator_BrowserValidator__6OAmL{padding:32px;height:100%;display:table-cell;text-align:center;vertical-align:middle}.BrowserValidator_BrowserValidator__6OAmL button{display:inline-block;width:auto}.BrowserValidator_Text__kYLaf{white-space:pre-wrap}

